<template>
  <SubNav class="subnav-position" :selected="'updates'" :id="id" />
  <main>
    <form v-if="update" @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link :to="{ name: 'OfferingUpdates', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Update</h6>
      <div class="form-outline mb-4">
        <label>Title</label>
        <input
          type="text"
          required
          v-model="update.title"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Update</label>
        <QuillEditor
          v-model:content="update.description"
          contentType="html"
          theme="snow"
        />
      </div>
      <div class="form-outline mb-4">
        <div v-if="update.mainImageURL" class="edit-large-picture">
          <img :src="update.mainImageURL" />
        </div>
        <div>
          <label>Upload Main Update Image</label>
        </div>
        <div class="error">{{ errorImage }}</div>
        <div v-if="isPending">
          <input type="file" @change="handleMainImage" disabled />
        </div>
        <div v-else><input type="file" @change="handleMainImage" /></div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingUpdates', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Update
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
  <Footer v-if="update" />
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import SubNav from "@/components/navigation/SubNav.vue";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import Footer from "@/components/navigation/Footer.vue";
import { timestamp } from "@/firebase/config";

export default {
  props: ["id", "updateId"],
  components: { SubNav, Footer, QuillEditor },
  setup(props) {
    const isPending = ref(false);
    const formError = ref(null);
    const { document: update } = getDocument("offeringUpdates", props.updateId);
    const { error: errorUpdate, updateDoc } = useDocument(
      "offeringUpdates",
      props.updateId
    );
    const router = useRouter();
    const { error: errorDelete, deleteDoc: deleteUpdate } = useDocument(
      "offeringUpdates",
      props.updateId
    );
    const {
      error: errorImage,
      url,
      filePath,
      urlThumb,
      filePathThumb,
      urlMedium,
      filePathMedium,
      uploadImageResize,
      deleteImage
    } = useStorage();

    const saveDetails = async () => {
      await updateDoc({
        title: update.value.title,
        description: update.value.description,
        mainImageFilePath: update.value.mainImageFilePath
          ? update.value.mainImageFilePath
          : "",
        mainImageURL: update.value.mainImageURL
          ? update.value.mainImageURL
          : "",
        mainImageFilePathThumb: update.value.mainImageFilePathThumb
          ? update.value.mainImageFilePathThumb
          : "",
        mainImageURLThumb: update.value.mainImageURLThumb
          ? update.value.mainImageURLThumb
          : "",
        mainImageFilePathMedium: update.value.mainImageFilePathMedium
          ? update.value.mainImageFilePathMedium
          : "",
        mainImageURLMedium: update.value.mainImageURLMedium
          ? update.value.mainImageURLMedium
          : "",
        editedAt: timestamp()
      });
    };

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        await saveDetails();
        if (errorUpdate.value) {
          formError.value = errorImage.value;
        } else {
          router.push({ name: "OfferingUpdates", params: { id: props.id } });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      formError.value = null;
      errorImage.value = null;
      if (update.value.mainImageFilePath) {
        await deleteImage(update.value.mainImageFilePath);
      }
      if (update.value.mainImageFilePathThumb) {
        await deleteImage(update.value.mainImageFilePathThumb);
      }
      if (update.value.mainImageFilePathMedium) {
        await deleteImage(update.value.mainImageFilePathMedium);
      }

      if (!errorImage.value) {
        await deleteUpdate();
        isPending.value = false;
        if (errorDelete.value) {
          formError.value = errorDelete.value;
        } else {
          router.push({ name: "OfferingUpdates", params: { id: props.id } });
        }
      } else {
        formError.value = errorImage.value;
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleMainImage = async e => {
      isPending.value = true;
      formError.value = null;
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        errorImage.value = null;
        //Delete the existing images so our resize doesn't break
        if (update.value.mainImageFilePath) {
          await deleteImage(update.value.mainImageFilePath);
          console.log(`Storage Update: ${errorImage.value}`);
          update.value.mainImageFilePath = "";
          update.value.mainImageURL = "";
        }
        if (update.value.mainImageFilePathThumb) {
          await deleteImage(update.value.mainImageFilePathThumb);
          console.log(`Storage Update: ${errorImage.value}`);
          update.value.mainImageFilePathThumb = "";
          update.value.mainImageURLThumb = "";
        }
        if (update.value.mainImageFilePathMedium) {
          await deleteImage(update.value.mainImageFilePathMedium);
          console.log(`Storage Update: ${errorImage.value}`);
          update.value.mainImageFilePathMedium = "";
          update.value.mainImageURLMedium = "";
        }

        errorImage.value = null;
        await uploadImageResize(
          `offeringUpdates/${props.id}/${props.updateId}`,
          "mainImage",
          selected
        );
        if (errorImage.value) {
          formError.value = errorImage.value;
        } else {
          update.value.mainImageFilePath = filePath.value;
          update.value.mainImageURL = url.value;
          update.value.mainImageFilePathThumb = filePathThumb.value;
          update.value.mainImageURLThumb = urlThumb.value;
          update.value.mainImageFilePathMedium = filePathMedium.value;
          update.value.mainImageURLMedium = urlMedium.value;

          await saveDetails();
          if (errorUpdate.value) {
            formError.value = errorUpdate.value;
          }
        }
      } else {
        formError.value = errorImage.value =
          "Please select an image file (png or jpeg)";
      }
      isPending.value = false;
    };

    return {
      isPending,
      formError,
      errorImage,
      update,
      handleSubmit,
      handleDelete,
      handleMainImage
    };
  }
};
</script>

<style>
</style>